import { nextTick, reactive, ref, watch } from "vue";

export default function () {
  const proxy = this;

  const dataStore = proxy.$usePiniaModule("dataStore");

  /* 盒子1 */
  // 描述
  const describeBox1 = [
    "延期退款",
    "有保障",
    "性价比高",
    "可视化开发过程",
    "可迭代的代码规范",
  ];
  // 列表1
  const wrappBox1__list1 = [
    {
      title: "可全程把控项目",
      text: "每周一汇报项目进度",
      icon: require("@/assets/images/box-1__group-1.png"),
    },
    {
      title: "多重保障保交付",
      text: "可在合同内约定赔付标准",
      icon: require("@/assets/images/box-1__group-2.png"),
    },
    {
      title: "收费清晰明了",
      text: "精确到单项功能所需工时",
      icon: require("@/assets/images/box-1__group-3.png"),
    },
    {
      title: "身经百战的工程师",
      text: "工程师至少五年以上开发经验",
      icon: require("@/assets/images/box-1__group-4.png"),
    },
  ];

  /* 盒子2 */
  // 列表1
  const wrappBox2__list1 = [
    {
      title: "APP定制",
      text: "高端、高效、省心APP定制开发，实现传统到移动互联华丽变身。",
      icon: require("@/assets/images/box-2__group-1.png"),
    },
    {
      title: "公众号&小程序定制 ",
      text: `微信小程序，让您的项目更有利于传播，客
  户获取更快速，发布信息更广泛，使用更轻
  便。`,
      icon: require("@/assets/images/box-2__group-2.png"),
    },
    {
      title: "web系统定制",
      text: `丰富系统架构经验、高并发、高安全性解
  决方案 ，助力企业管理。`,
      icon: require("@/assets/images/box-2__group-3.png"),
    },
    {
      title: "物联网&智能共享",
      text: `资深开发工程师，专业深耕软件硬件开发
  编程，一站式解决您的需求。`,
      icon: require("@/assets/images/box-2__group-4.png"),
    },
    {
      title: "企业系统定制",
      text: `针对企业开发的ERP、OA、CRM等系统，
  可对接第三方系统打通数据应用。`,
      icon: require("@/assets/images/box-2__group-5.png"),
    },
    {
      title: "电商系统",
      text: `全网互通多端融合引领创新，商城拥有拼
  团/拍卖等数十项主流功能!`,
      icon: require("@/assets/images/box-2__group-6.png"),
    },
  ];

  /* 盒子3 */
  const wrappBox3__list1 = [
    {
      icon: require("@/assets/images/project-list-card/project-32.png"),
      name: "商机小程序",
      showFreeTrialTag: true,
      backgroundColor: "rgba(38, 157, 255, 1)",
      routeQuery: { name: "businessOportunity" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-33.png"),
      name: "内部招商系统",
      backgroundColor: "rgba(115, 192, 151, 1)",
      showFreeTrialTag: true,
      routeQuery: { name: "attractInvestment" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-3.png"),
      name: "智能AR眼睛",
      showFreeTrialTag: true,
      backgroundColor: "rgba(54, 188, 248, 1)",
      routeQuery: { name: "smartArGlasses" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-4.png"),
      name: "云宠智能",
      showFreeTrialTag: true,
      backgroundColor: "rgba(47, 111, 253, 1)",
      routeQuery: { name: "cloudPetIntelligence" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-6.png"),
      name: "中国人民银行",
      backgroundColor: "rgba(254, 0, 2, 1)",
      routeQuery: { name: "zhBankingFinance" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-5.png"),
      name: "南天明鉴",
      showFreeTrialTag: true,
      backgroundColor: "rgba(43, 115, 207, 1)",
      routeQuery: { name: "nantianJudicial" },
    },
  ];

  /* 盒子4 */
  const serviceProcessList = ref([
    {
      title: "初步沟通",
      brief: "沟通需求 · 梳理需求",
      describe: `沟通了解需求&初步规划需求，确认项目的可行性、合法性等。确保避免未来因技术无法开发造成的无效沟通。`,
      index: 0,
      contentImage: require("@/assets/images/home/flow-path-0.png"),
    },
    {
      title: "确认合作",
      brief: "输出功能清单 · 签订合同",
      describe: `输出《功能清单》确认项目1.0版本、合同范围及边界签订合同。前期耗时整理的需求清晰明了，云效避免需求模糊进入合作后条条款款都要收款“先上车后补票”的行径。
  随后确认整体需求进行工作计划安排输出《需求工作计划》；tips：按计划实施工作可以对整个项目的大体时间流程清晰把控。`,
      index: 1,
      contentImage: require("@/assets/images/home/flow-path-1.png"),
    },
    {
      title: "设计原型",
      brief: "整理UML图 · 设计原型",
      describe: `产品经理进行精细化需求了解和梳理后，由粗到细输出：《流程图》、《状态图》、《原型架构》、《原型设计》进一步明确页面&页面跳转交互效果。`,
      index: 2,
      contentImage: require("@/assets/images/home/flow-path-2.png"),
    },
    {
      title: "UI设计",
      brief: "风格稿确认 · UI设计",
      describe: `UI设计师了解项目定位与客户期望后，进行风格稿的设计，并附上设计理念与设计规范给客户确认，待客户选定UI风格稿以后开始UI的设计。在设计过程中会充分考虑交互与规范。`,
      index: 3,
      contentImage: require("@/assets/images/home/flow-path-3.png"),
    },
    {
      title: "开发项目",
      brief: "需求评审 · 架构设计 · 编码",
      describe: `确认需求与Ui设计后进入开发阶段，输出《项目开发计划》并按计划进行每日开发实施，同时输出文件《数据库设计》、《数据字典》、《接口文档》、《项目部署文档》等文件。`,
      index: 4,
      contentImage: require("@/assets/images/home/flow-path-4.png"),
    },
    {
      title: "测试项目",
      brief: "单元测试 · 集成测试",
      describe: `开发过程中同步测试项目进行单元测试，按照开发计划项目开发完成后会进行全面系统测试，输出《测试用例》、《测试报告》。`,
      index: 5,
      contentImage: require("@/assets/images/home/flow-path-5.png"),
    },
    {
      title: "验收项目",
      brief: "邀约客户逐条检验项目功能",
      describe: `当项目符合验收标准后云效会发起验收，按照《验收报告》进行逐条检验功能，确定没问题后用户签订验收报告，正式进入交付环节。`,
      index: 6,
      contentImage: require("@/assets/images/home/flow-path-6.png"),
    },
    {
      title: "交付项目",
      brief: "交付源代码&其他所有资料",
      describe: `签订《验收报告》后云效会收集项目开发过程中产生的所有文档及源代码进行交付到合同约定的邮箱账户。`,
      index: 7,
      contentImage: require("@/assets/images/home/flow-path-7.png"),
    },
  ]);

  // ************************************************************************************
  // 操作 START
  // ************************************************************************************

  // 跳转案例列表
  const jumpCase = () => {
    const call = () => {
      proxy.$router.push("/case");
    };

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function () {
      document
        .getElementsByClassName("wrapper-box-3")[0]
        .getElementsByClassName("list-more")[0]
        .click();
    });
    call();
  };

  // 跳转置顶新闻
  const jumpTopNewDetail = (item) => {
    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function () {
      document
        .getElementsByClassName("wrapper-box-5")[0]
        .getElementsByClassName("wrap-left")[0]
        .click();
    });

    jumpDetail(item);
  };
  // 跳转新闻详情
  const jumpDetail = (item, index) => {
    const call = () => {
      proxy.$router.push({
        path: "/newsInformationDetail",
        query: {
          id: item.id,
        },
      });
    };

    if (!proxy.$isEmpty(index)) {
      // 记录鼠标操作
      dataStore.setGlobalClickRecord(
        function (params) {
          const { value } = params;
          document
            .getElementsByClassName("wrapper-box-5")[0]
            .getElementsByClassName("item")
            [value].click();
        },
        {
          value: index,
        }
      );
    }

    call();
  };

  // 跳转新闻列表
  const jumpNewsList = () => {
    const call = () => {
      proxy.$router.push("/newsInformation");
    };

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function () {
      document
        .getElementsByClassName("wrapper-box-5")[0]
        .getElementsByClassName("list-more")[0]
        .click();
    });
    call();
  };

  // ************************************************************************************
  // 操作 END
  // ************************************************************************************

  // ************************************************************************************
  // 查询 START
  // ************************************************************************************

  // 查询新闻列表
  const getNewList = async () => {
    try {
      const res = await this.$storeDispatch("fetchGetNewNewsListReporting", {
        pageNum: 1,
        pageSize: 4,
      });
      const result = res.result;

      return result;
    } catch (error) {
      return [];
    }
  };

  // ************************************************************************************
  // 查询 END
  // ************************************************************************************

  return {
    getNewList,
    describeBox1,
    wrappBox1__list1,
    wrappBox2__list1,
    wrappBox3__list1,
    serviceProcessList,

    jumpCase,
    jumpDetail,
    jumpNewsList,
    jumpTopNewDetail,
  };
}
