<template>
  <div class="home-container pc-wrap">
    <!-- 盒子1 -->
    <div class="wrapper-box wrapper-box-1">
      <img class="box-bg" :src="homeTopBg1" alt="" />

      <div class="describe-box is-wrapper-box">
        <div class="row row-1 fontRegular">云效科技-软件开发服务商</div>

        <div class="row row-2 fontBold">我们承诺延期退款！</div>

        <div class="row row-3">
          <div
            class="row-item fontRegular"
            v-for="(item, index) in describeBox1"
            :key="'box-1__row' + index"
          >
            <p class="bar" v-if="index"></p>
            <div class="label fontRegular">
              {{ item }}
            </div>
          </div>
        </div>

        <div class="row-4">
          <div class="qrcode-wrap">
            <div class="qrcode-image">
              <img :src="qrcodeImage" alt="" />
            </div>
            <p class="label fontRegular">扫一扫添加微信在线咨询</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 介绍列表 -->
    <div class="describe-list is-wrapper-box">
      <div
        class="row-item"
        v-for="(item, index) in wrappBox1__list1"
        :key="'list-item-1' + index"
      >
        <div class="image">
          <img :src="item.icon" alt="" />
        </div>
        <div class="right">
          <p class="title fontRegular">{{ item.title }}</p>
          <p class="text fontRegular">{{ item.text }}</p>
        </div>
      </div>
    </div>

    <!-- 盒子2 -->
    <div class="wrapper-box wrapper-box-2">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">云效可以开发什么 ?</h2>
          <div class="sub-title fontRegular">
            我们拥有专业而强大的软件开发团队、丰富的行业经验、精细的服务准则
          </div>
        </div>

        <div class="wrapp-group-box">
          <div
            class="group-item"
            v-for="(item, index) in wrappBox2__list1"
            :key="'box-2__item-1' + index"
          >
            <div class="item">
              <div class="image">
                <img :src="item.icon" alt="" />
              </div>

              <div class="title fontRegular">
                {{ item.title }}
              </div>

              <div class="text fontRegular">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子3 -->
    <div class="wrapper-box wrapper-box-3">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">我们的项目案例</h2>
          <div class="sub-title fontRegular">
            以精益求精的态度认真对待每一个项目
          </div>
        </div>

        <div class="wrapp-group-box">
          <list-card
            :src="item.icon"
            :name="item.name"
            :backgroundColor="item.backgroundColor"
            :routeQuery="item.routeQuery"
            v-for="(item, index) in wrappBox3__list1"
            :key="'box-3__item-1' + index"
            :keyIndex="index"
            class="addPadding"
            :showFreeTrialTag="item.showFreeTrialTag"
          ></list-card>
        </div>

        <!-- 查看更多 -->
        <div class="list-more" @click="jumpCase">
          <img :src="listMoreImage" alt="" />
        </div>
      </div>
    </div>

    <!-- 盒子4 -->
    <div class="wrapper-box wrapper-box-4">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">我们的服务流程</h2>
          <div class="sub-title fontRegular">
            我们提供【从想法到上架】【从0到1】的全流程服务支持
          </div>
        </div>

        <div class="wrapp-group-box">
          <div class="wrap-left">
            <div class="list-box">
              <div
                class="item"
                v-for="(item, index) in serviceProcessList"
                :key="'box-4-index' + index"
                :class="{ 'is-active': chooseProcessItem.index == index }"
                @mouseover="onChangeProcess(item)"
              >
                <div class="item-left">
                  <p class="sort-label fontBold">
                    {{ (index + 1).toString().padStart(2, 0) }}
                  </p>
                  <div class="info-label">
                    <p class="title fontBold">{{ item.title }}</p>
                    <p class="describe fontRegular">{{ item.brief }}</p>
                  </div>
                </div>
                <div class="item-right">
                  <div class="arrow-item">
                    <img
                      class="row-icon"
                      :src="arrowBlueImage"
                      v-if="chooseProcessItem.index == index"
                    />
                    <img class="row-icon" :src="arrowImage" alt="" v-else />
                  </div>
                </div>
              </div>

              <div
                class="active-bar"
                :style="{ top: chooseBarPosTop + 'px' }"
              ></div>
            </div>
          </div>

          <div class="wrap-right">
            <div class="up-title">
              <div class="title">
                <p class="text fontBold">{{ chooseProcessItem.title }}</p>
                <img class="bar" :src="borderBarImage" alt="" />
              </div>
              <p class="describe fontRegular">
                {{ chooseProcessItem.describe }}
              </p>
            </div>

            <div class="down-content">
              <img :src="chooseProcessItem.contentImage" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子5 -->
    <div class="wrapper-box wrapper-box-5">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">企业资讯</h2>
          <div class="sub-title fontRegular">了解最新资讯，聚焦行业动态</div>
        </div>

        <div class="wrapp-group-box">
          <div class="wrap-left" @click="jumpTopNewDetail(firstNewDetail)">
            <ml-image
              class="preview-cover"
              :src="firstNewDetail.newsCover"
            ></ml-image>

            <div class="describe">
              <div class="title oneLineOver fontBold">
                {{ firstNewDetail.title }}
              </div>
              <p class="label fontMedium">/ 阅读全文</p>
            </div>
            <!-- 遮罩层 -->
            <div class="mask"></div>
          </div>

          <div class="wrap-right">
            <div class="list">
              <div
                class="item"
                v-for="(item, index) in residueNewList"
                :key="'new' + index"
                @click="jumpDetail(item, index)"
              >
                <p class="time fontMedium">
                  {{ $timeSpToDate(item.updateTime) }}
                </p>
                <p class="title ml-line-2 fontBold">{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 查看更多 -->
        <div class="list-more" @click="jumpNewsList">
          <img :src="listMoreImage" alt="" />
        </div>
      </div>
    </div>

    <!-- 盒子6 -->
    <div class="wrapper-box wrapper-box-6">
      <div class="wrapper-content">
        <div class="wrapp-title">
          <h2 class="fontBold">战略伙伴</h2>
          <div class="sub-title fontRegular">
            信任相依,协作共赢,战略合作,见证辉煌
          </div>
        </div>

        <div class="wrapp-group-box">
          <!-- 实列 -->
          <div class="list">
            <div
              class="list-item"
              v-for="(item, index) in 30"
              :key="'list' + index"
            >
              <img class="logo-color" :src="getIconColor6(index)" alt="" />
            </div>

            <!-- 横 -->
            <div
              :style="{ top: `${(index + 1) * 160}px` }"
              class="list-col"
              v-for="(item, index) in 4"
              :key="'col' + index"
            ></div>

            <!-- 纵 -->
            <div
              :style="{ left: `${(index + 1) * 300}px` }"
              class="list-row"
              v-for="(item, index) in 5"
              :key="'col' + index"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子7 -->
    <div class="wrapper-box wrapper-box-7">
      <img class="box-bg" :src="homeTopBg3" alt="" />

      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-group-box">
          <!-- 表单 -->
          <contact-form></contact-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, onMounted } from "vue";
import datajs from "../data.js";
import anime from "animejs";

const { proxy } = getCurrentInstance();
// 辅助参数
const {
  describeBox1,
  wrappBox1__list1,
  wrappBox2__list1,
  wrappBox3__list1,
  serviceProcessList,
  getNewList,

  jumpCase,
  jumpDetail,
  jumpNewsList,
  jumpTopNewDetail,
} = datajs.apply(proxy);

// 图片
// 首页顶部背景
const homeTopBg1 = require("@/assets/images/home-top-bg-1.png");
// 首页底部表单背景
const homeTopBg3 = require("@/assets/images/home-top-bg-3.png");
// 二维码
const qrcodeImage = require("@/assets/images/call-qrocde.png");
// 查看更多
const listMoreImage = require("@/assets/images/list-more.png");
// 指向
const arrowImage = require("@/assets/images/home/row-right.png");
// 指向
const arrowBlueImage = require("@/assets/images/home/row-right-btn.png");
// 边线标识
const borderBarImage = require("@/assets/images/home/border-bar.png");

// 选中 展示的服务流程
const chooseProcessItem = ref(serviceProcessList.value[0]);
// 选中标识相对位置
const chooseBarPosTop = computed(() => chooseProcessItem.value.index * 103);
// 监听选中
const onChangeProcess = (item) => {
  chooseProcessItem.value = item;
};

/* 盒子5 */
// 新闻列表
const newList = ref([]);
// 展示第一条
const firstNewDetail = computed(() => {
  const [first = {}] = newList.value;
  return first;
});
// 剩余3条
const residueNewList = computed(() => {
  const [first = {}, ...news] = newList.value;
  return news;
});

/* 盒子6 */
const getIconColor6 = (name) => {
  return require(`@/assets/images/home/logo-color/logo-${name}.png`);
};

// 初始盒子6的监听
const initWrapBox6OnResize = (target) => {
  const { innerWidth } = target;

  const wrapBox6El = document.getElementsByClassName("wrapper-box-6");
  if (wrapBox6El.length) {
    const wrapBox6Element = wrapBox6El[0].getElementsByClassName("list")[0];
    const wrapBox6Element__width = wrapBox6Element.clientWidth;

    // console.log(innerWidth, wrapBox6Element__width);
    // console.log(innerWidth <= wrapBox6Element__width);
    if (innerWidth <= wrapBox6Element__width) {
      // 比例
      const wrapBox6Element__scale = innerWidth / wrapBox6Element__width;
      // console.log(wrapBox6Element__scale);
      wrapBox6Element.style = `transform:scale(${wrapBox6Element__scale});`;
    }
  }
};

// 设置一些动画
const setPageSomeAnimation = () => {
  anime
    .timeline({
      direction: "reverse",
      easing: "easeInOutSine",
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-3",
      translateX: 140,
      opacity: 0,
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-2",
      translateX: -140,
      opacity: 0,
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-1",
      translateX: 140,
      opacity: 0,
    });
};

// 初始
const init = async () => {
  setPageSomeAnimation();
  newList.value = await getNewList();

  // 设置监听 窗口范围变化
  window.onresize = (e) => {
    const { target } = e;
    initWrapBox6OnResize(target);
  };

  initWrapBox6OnResize(window);
};

onMounted(async () => {
  init();
});
</script>

<style lang="scss" scoped>
.home-container {
  &.pc-wrap {
    .is-wrapper-box {
      // width: calc(100vw - 720px);
      width: 100%;
      max-width: 1200px;
      margin: auto;
    }

    .wrapper-box {
      .wrapp-title {
        text-align: center;
        h2 {
          font-size: 38px;
          color: rgba(51, 51, 51, 1);
        }
        .sub-title {
          font-size: 16px;
          color: rgba(102, 102, 102, 1);
          line-height: 45px;
        }
      }

      .wrapper-content {
        .list-more {
          margin-top: 80px;
          text-align: center;
          cursor: pointer;
          img {
            margin: auto;
            width: 160px;
            height: 48px;
          }
        }
      }

      // 盒子1
      &-1 {
        // background-image: url(../../../assets/images/home-top-bg-1.png);
        // background-repeat: no-repeat;
        // background-attachment: fixed;
        // background-position: center 70px;
        // background-size: auto 720px;
        width: 100%;
        height: 100%;
        max-height: 5000px;
        position: relative;

        .box-bg {
          width: 100%;
          position: absolute;
          z-index: -1;
          height: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
        }

        .describe-box {
          margin: 0 auto;
          color: white;
          padding-top: 160px;
          padding-bottom: 126px;
          position: relative;
          .row {
            text-align: center;
            &-1 {
              font-size: 32px;
              margin-bottom: 17px;
            }
            &-2 {
              font-size: 80px;
              font-weight: bold;
              margin-bottom: 25px;
            }
            &-3 {
              display: flex;
              align-items: center;
              margin-bottom: 50px;
              justify-content: center;
              .row-item {
                display: flex;
                align-items: center;
                .label {
                  // color: rgba(255, 242, 170, 1);
                  color: white;
                  font-size: 24px;
                }

                .bar {
                  width: 1px;
                  height: 24px;
                  background: linear-gradient(
                    180deg,
                    rgba(239, 151, 151, 0) 0%,
                    #ffafaf 50%,
                    rgba(255, 255, 255, 0) 100%
                  );
                  opacity: 0.45;
                  margin: 0 22px;
                }
              }
            }

            &-4 {
              .qrcode-wrap {
                .qrcode-image {
                  display: flex;
                  justify-content: center;
                  img {
                    width: 110px;
                    height: 110px;
                  }
                }
                .label {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 1);
                  text-align: center;
                  line-height: 35px;
                }
              }
            }

            &-5 {
            }
          }
        }
      }

      // 盒子2
      &-2 {
        background-image: url(../../../assets/images/home-top-bg-2.png);
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-position: center top;
        background-size: 100% 100%;
        margin-top: 80px;
        padding-bottom: 50px;
        background-color: white;
        .wrapper-content {
          margin: auto;

          .wrapp-group-box {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, auto);
            .group-item {
              padding: 30px;

              .item {
                transition: border-color 300ms;
                padding: 27px;
                background: #ffffff;
                box-shadow: 0px 0px 10px 1px rgba(82, 110, 255, 0.1);
                border: 1px solid #ffffff;
                border-radius: 8px;
                text-align: center;
                height: 320px;
                box-sizing: border-box;
                &:hover {
                  border-color: #005de8;
                  box-shadow: 0px 0px 20px 1px rgba(82, 110, 255, 0.2);
                }

                .image {
                  margin: 10px auto;
                  width: 92px;
                  height: 92px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .title {
                  margin: 35px auto 8px;
                  font-size: 16px;
                  font-weight: bold;
                  color: rgba(51, 51, 51, 1);
                }
                .text {
                  font-size: 16px;
                  color: rgba(153, 153, 153, 1);
                  line-height: 30px;
                }
              }
            }
          }
        }
      }

      // 盒子3
      &-3 {
        .wrapper-content {
          margin: 80px auto 40px;
          .wrapp-group-box {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, auto);

            .list-card-container {
              padding: 37px;
              box-sizing: border-box;
            }
          }

          .list-more {
            margin-top: 30px;
          }
        }
      }

      // 盒子4
      &-4 {
        background-color: rgba(247, 250, 253, 1);
        padding-bottom: 26px;
        padding-top: 0.1px;
        .wrapper-content {
          margin: 80px auto;
          margin-bottom: 0;

          .wrapp-group-box {
            display: grid;
            grid-template-columns: 350px 1fr;

            .wrap {
              &-left {
                .list-box {
                  position: relative;
                  .active-bar {
                    width: 100%;
                    height: 99px;
                    transition: 300ms;
                    top: 0;
                    left: 0;
                    position: absolute;
                    background: rgba(10, 65, 255, 1);
                    border-radius: 6px;
                    box-shadow: 0px 20px 30px 1px rgba(82, 110, 255, 0.5);
                  }

                  .item {
                    display: grid;
                    grid-template-columns: 1fr 24px;
                    padding: 24px;
                    box-sizing: border-box;
                    cursor: pointer;
                    position: relative;
                    z-index: 1;

                    &.is-active {
                      .item-left {
                        color: white;
                        transition: color 300ms;

                        .info-label {
                          .title {
                          }
                          .describe {
                            color: #ffffff99;
                            transition: color 300ms;
                          }
                        }
                      }
                      .item-right {
                        .arrow-item {
                          transition: background 300ms, box-shadow 500ms;
                          background-color: white;
                          box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.4);
                        }
                      }
                    }

                    &-left {
                      display: grid;
                      grid-template-columns: auto 1fr;
                      align-items: center;
                      color: rgba(155, 158, 179, 1);
                      transition: color 300ms;
                      .sort-label {
                        font-size: 38px;
                        font-weight: bold;
                        margin-right: 21px;
                      }
                      .info-label {
                        .title {
                          font-size: 18px;
                          font-weight: bold;
                        }
                        .describe {
                          font-size: 16px;
                          line-height: 27px;
                          transition: color 100ms;
                        }
                      }
                    }
                    &-right {
                      display: flex;
                      align-items: flex-end;
                      justify-content: center;

                      padding: 2px 0;
                      .arrow-item {
                        width: 24px;
                        height: 24px;
                        border-radius: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: background 300ms, box-shadow 500ms;
                        .row-icon {
                          width: 13px;
                        }
                      }
                    }
                  }
                }
              }
              &-right {
                display: grid;
                grid-template-rows: auto 1fr;
                align-items: center;
                margin-top: 42px;
                margin-left: 110px;
                margin-bottom: 30px;
                .up-title {
                  .title {
                    display: flex;
                    flex-direction: column;
                    .text {
                      font-size: 22px;
                      font-weight: bold;
                    }
                    .bar {
                      width: 24px;
                      height: 3px;
                      margin-top: 8px;
                      margin-bottom: 16px;
                    }
                  }
                  .describe {
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                  }
                }

                .down-content {
                  img {
                    margin-left: -20px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      // 盒子5
      &-5 {
        background-image: url(../../../assets/images/home-top-bg-2.png);
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-position: center top;
        background-size: 100% 100%;
        padding: 1px 0;
        background-color: white;
        .wrapper-content {
          margin: 80px auto;

          .wrapp-group-box {
            display: grid;
            grid-template-columns: auto 1fr;
            margin-top: 60px;
            .wrap {
              &-left {
                position: relative;
                &:hover {
                  .describe {
                    bottom: 50px;
                    .label {
                      opacity: 1;
                    }
                  }

                  .mask {
                    opacity: 1;
                  }
                }

                .preview-cover {
                  width: 710px;
                  height: 425px;
                  border-radius: 10px;
                  cursor: pointer;
                }

                .describe {
                  position: absolute;
                  color: white;
                  bottom: 10px;
                  left: 30px;
                  z-index: 2;
                  transition: all 0.3s;
                  .title {
                    width: 645px;
                    font-size: 17px;
                    font-weight: bold;
                    z-index: 1;
                  }
                  .label {
                    margin-top: 20px;
                    font-size: 14px;
                    opacity: 0;
                    cursor: pointer;
                    transition: all 0.3s;
                  }
                }

                .mask {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 425px;
                  border-radius: 10px;
                  opacity: 0;
                  transition: all 0.3s;
                  background: linear-gradient(
                    to bottom,
                    transparent,
                    rgba(112, 112, 112, 0.3)
                  );
                }
              }
              &-right {
                margin-left: 40px;
                .list {
                  .item {
                    border-bottom: 1px solid rgba(10, 65, 255, 0.1);
                    margin-bottom: 31px;
                    cursor: pointer;

                    > p {
                      margin-bottom: 29px;
                    }

                    .time {
                      font-size: 14px;
                      color: rgba(153, 153, 153, 1);
                    }
                    .title {
                      color: rgba(44, 44, 44, 1);
                      font-size: 17px;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // 盒子6
      &-6 {
        .wrapper-content {
          margin: auto;
          margin-top: 80px;

          .wrapp-group-box {
            width: calc(100vw - 120px);
            margin: auto;
            padding: 30px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .list {
              position: relative;
              display: grid;
              grid-template-columns: repeat(6, 300px);
              grid-template-rows: repeat(5, 160px);
              justify-items: center;
              align-items: center;
              cursor: pointer;
              width: fit-content;
              margin: auto;

              .list-item {
                // ._show {
                //   opacity: 1;
                //   width: initial;
                //   height: initial;
                // }
                // ._hide {
                //   opacity: 0;
                //   width: 0;
                //   height: 0;
                // }

                .logo-color {
                  transition: all 0.2s;
                }
                &:hover {
                  .logo-color {
                    transition: all 0.2s;
                    transform: scale(1.2);
                  }
                }
              }

              .list-col {
                position: absolute;
                width: 100%;
                background: linear-gradient(
                  91deg,
                  rgba(128, 128, 128, 0) 0,
                  rgba(128, 128, 128, 0.2) 60%,
                  rgba(128, 128, 128, 0) 99%
                );
                height: 1px;
              }

              .list-row {
                position: absolute;
                height: 100%;
                width: 1px;
                background: linear-gradient(
                  180deg,
                  rgba(128, 128, 128, 0) 0,
                  rgba(128, 128, 128, 0.2) 60%,
                  rgba(128, 128, 128, 0) 99%
                );
              }
            }
          }
        }
      }

      &-7 {
        // background-image: url(../../../assets/images/home-top-bg-3.png);
        // background-repeat: no-repeat;
        // background-position: center 0;
        // background-size: auto 584px;
        padding: 60px 0;
        width: 100%;
        height: 100%;
        max-height: 5000px;
        position: relative;
        .box-bg {
          width: 100%;
          position: absolute;
          z-index: -1;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          top: 0;
          left: 0;
        }
      }
    }

    .describe-list {
      margin: auto;
      margin-top: -50px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      position: relative;
      z-index: 2;
      .row-item {
        padding: 25px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        background-color: white;
        box-shadow: 0px 0px 20px 1px rgba(82, 110, 255, 0.16);
        border-radius: 6px;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        .image {
          width: 40px;
          height: 40px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .right {
          margin-left: 16px;
          .title {
            color: rgba(51, 51, 51, 1);
            font-weight: bold;
            font-size: 14px;
          }
          .text {
            margin-top: 4px;
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }
  }
}
</style>
