<template>
  <div class="home-container mobile-wrap">
    <!-- 盒子1 -->
    <div class="wrapper-box wrapper-box-1">
      <div class="describe-box is-wrapper-box">
        <div class="row row-1 fontRegular">云效科技-软件开发服务商</div>

        <div class="row row-2 fontBold">我们承诺延期退款！</div>

        <div class="row row-3">
          <div class="line-1">
            <div
              class="row-item"
              v-for="(item, index) in wrapBox1__label3"
              :key="'box-1__row' + index"
            >
              <p class="bar" v-if="index"></p>
              <div class="label fontRegular">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="line-2">
            <div
              class="row-item"
              v-for="(item, index) in wrapBox1__label2"
              :key="'box-1__row' + index"
            >
              <p class="bar" v-if="index"></p>
              <div class="label fontRegular">
                {{ item }}
              </div>
            </div>
          </div>
        </div>

        <div class="row-4">
          <div class="qrcode-wrap">
            <div class="qrcode-image">
              <img :src="qrcodeImage" alt="" />
            </div>
            <p class="label fontRegular">扫一扫添加微信在线咨询</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 介绍列表 -->
    <div class="describe-list is-wrapper-box">
      <div
        class="row-item"
        v-for="(item, index) in wrappBox1__list1"
        :key="'list-item-1' + index"
      >
        <div class="image">
          <img :src="item.icon" alt="" />
        </div>
        <div class="right">
          <p class="title fontBold">{{ item.title }}</p>
          <p class="text fontRegular">{{ item.text }}</p>
        </div>
      </div>
    </div>

    <!-- 盒子2 -->
    <div class="wrapper-box wrapper-box-2">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">云效可以开发什么 ?</h2>
          <div class="sub-title fontRegular">
            我们拥有专业而强大的软件开发团队、丰富的行业经验、精细的服务准则
          </div>
        </div>

        <div class="wrapp-group-box">
          <div
            class="group-item"
            v-for="(item, index) in wrappBox2__list1"
            :key="'box-2__item-1' + index"
          >
            <div class="item">
              <div class="image">
                <img :src="item.icon" alt="" />
              </div>

              <div class="title fontBold">
                {{ item.title }}
              </div>

              <div class="text fontRegular">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子3 -->
    <div class="wrapper-box wrapper-box-3">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">我们的项目案例</h2>
          <div class="sub-title fontRegular">
            以精益求精的态度认真对待每一个项目
          </div>
        </div>

        <div class="wrapp-group-box">
          <list-card
            :isPointer="false"
            :src="item.icon"
            :name="item.name"
            :backgroundColor="item.backgroundColor"
            :isExpandName="true"
            :routeQuery="item.routeQuery"
            v-for="(item, index) in wrappBox3__list1"
            :key="'box-3__item-1' + index"
            :keyIndex="index"
            :showFreeTrialTag="item.showFreeTrialTag"
          ></list-card>
        </div>

        <!-- 查看更多 -->
        <div class="list-more" @click="jumpCase">
          <img :src="listMoreImage" alt="" />
        </div>
      </div>
    </div>

    <!-- 盒子4 -->
    <div class="wrapper-box wrapper-box-4">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">我们的服务流程</h2>
          <div class="sub-title fontRegular">
            我们提供【从想法到上架】【从0到1】的全流程服务支持
          </div>
        </div>

        <div class="wrapp-group-box">
          <div class="wrap-left hide-scrollbar">
            <div class="list-box">
              <div
                class="item"
                v-for="(item, index) in serviceProcessList"
                :key="'box-4-index' + index"
                :class="{ 'is-active': chooseProcessItem.index == index }"
                @click="onChangeProcess(item)"
              >
                <div class="item-left">
                  <p class="sort-label fontBold">
                    {{ (index + 1).toString().padStart(2, 0) }}
                  </p>
                  <div class="info-label">
                    <p class="title fontBold">{{ item.title }}</p>
                    <p class="describe fontRegular">{{ item.brief }}</p>
                  </div>
                </div>
                <div class="item-right">
                  <div class="arrow-item">
                    <img
                      class="row-icon"
                      :src="arrowBlueImage"
                      v-if="chooseProcessItem.index == index"
                    />
                    <img class="row-icon" :src="arrowImage" alt="" v-else />
                  </div>
                </div>
              </div>

              <div
                class="active-bar"
                :style="{ left: chooseBarPosLeft + 'px' }"
              ></div>
            </div>
          </div>

          <div class="wrap-right">
            <div class="up-title">
              <div class="title">
                <p class="text fontBold">{{ chooseProcessItem.title }}</p>
                <img class="bar" :src="borderBarImage" alt="" />
              </div>
              <p class="describe fontRegular">
                {{ chooseProcessItem.describe }}
              </p>
            </div>

            <div class="down-content">
              <img :src="chooseProcessItem.contentImage" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子5 -->
    <div class="wrapper-box wrapper-box-5">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">企业资讯</h2>
          <div class="sub-title fontRegular">了解最新资讯，聚焦行业动态</div>
        </div>

        <div class="wrapp-group-box">
          <div class="wrap-left" @click="jumpTopNewDetail(firstNewDetail)">
            <ml-image
              class="preview-cover"
              :src="firstNewDetail.newsCover"
            ></ml-image>

            <div class="describe">
              <div class="title oneLineOver fontBold">
                {{ firstNewDetail.title }}
              </div>
              <p class="label fontMedium">/ 阅读全文</p>
            </div>
          </div>

          <div class="wrap-right">
            <div class="list">
              <div
                class="item"
                v-for="(item, index) in residueNewList"
                :key="'new' + index"
                @click="jumpDetail(item, index)"
              >
                <p class="time fontMedium">
                  {{ $timeSpToDate(item.updateTime) }}
                </p>
                <p class="title ml-line-2 fontBold">{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 查看更多 -->
        <div class="list-more" @click="jumpNewsList">
          <img :src="listMoreImage" alt="" />
        </div>
      </div>
    </div>

    <!-- 盒子6 -->
    <div class="wrapper-box wrapper-box-6">
      <div class="wrapper-content">
        <div class="wrapp-title">
          <h2>战略伙伴</h2>
          <div class="sub-title">信任相依,协作共赢,战略合作,见证辉煌</div>
        </div>

        <div class="wrapp-group-box">
          <!-- 实列 -->
          <div class="list">
            <div
              class="list-item"
              v-for="(item, index) in 30"
              :key="'list' + index"
            >
              <img class="logo-color" :src="getIconColor6(index)" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子7 -->
    <div class="wrapper-box wrapper-box-7">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-group-box">
          <!-- 表单 -->
          <contact-form></contact-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, onMounted } from "vue";
import datajs from "../data.js";
import anime from "animejs";

const { proxy } = getCurrentInstance();
// 辅助参数
const {
  describeBox1,
  wrappBox1__list1,
  wrappBox2__list1,
  wrappBox3__list1,
  serviceProcessList,
  getNewList,

  jumpCase,
  jumpDetail,
  jumpNewsList,
  jumpTopNewDetail,
} = datajs.apply(proxy);

// 图片
// 二维码
const qrcodeImage = require("@/assets/images/call-qrocde.png");
// 查看更多
const listMoreImage = require("@/assets/images/list-more.png");
// 指向
const arrowImage = require("@/assets/images/home/row-right.png");
// 指向
const arrowBlueImage = require("@/assets/images/home/row-right-btn.png");
// 边线标识
const borderBarImage = require("@/assets/images/home/border-bar.png");

// 选中 展示的服务流程
const chooseProcessItem = ref(serviceProcessList.value[0]);
// 选中标识相对位置
const chooseBarPosLeft = computed(
  () => chooseProcessItem.value.index * 251 + 20
);
// 监听选中
const onChangeProcess = (item) => {
  chooseProcessItem.value = item;
};

/* 盒子1 */
// 保证 前3条
const wrapBox1__label3 = computed(() => describeBox1.slice(0, 3));
// 保证 后2条
const wrapBox1__label2 = computed(() => describeBox1.slice(3, 5));

/* 盒子5 */
// 新闻列表
const newList = ref([]);
// 展示第一条
const firstNewDetail = computed(() => {
  const [first = {}] = newList.value;
  return first;
});
// 剩余3条
const residueNewList = computed(() => {
  const [first = {}, ...news] = newList.value;
  return news;
});

/* 盒子6 */
const getIconColor6 = (name) => {
  return require(`@/assets/images/home/logo-color/logo-${name}.png`);
};

// 设置一些动画
const setPageSomeAnimation = () => {
  anime
    .timeline({
      direction: "reverse",
      easing: "easeInOutSine",
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-3",
      translateX: 140,
      opacity: 0,
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-2",
      translateX: -140,
      opacity: 0,
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-1",
      translateX: 140,
      opacity: 0,
    });
};

onMounted(async () => {
  setPageSomeAnimation();
  newList.value = await getNewList();
});
</script>

<style lang="scss" scoped>
.home-container {
  &.mobile-wrap {
    .wrapper-box {
      .wrapp-title {
        text-align: center;
        h2 {
          font-size: 20px;
          color: rgba(51, 51, 51, 1);
        }
        .sub-title {
          font-size: 12px;
          color: rgba(102, 102, 102, 1);
          line-height: 20px;
          margin: 6px auto;
        }
      }

      .wrapper-content {
        .list-more {
          margin-top: 40px;
          text-align: center;
          img {
            margin: auto;
            width: 160px;
            height: 48px;
          }
        }
      }

      // 盒子1
      &-1 {
        background-image: url(../../../assets/images/home-top-bg-1.png);
        background-repeat: no-repeat;
        background-position: top;
        background-size: auto 530px;
        background-attachment: fixed;

        .describe-box {
          margin: 0 auto;
          color: white;
          padding-top: 53px;
          padding-bottom: 50px;
          position: relative;
          .row {
            text-align: center;
            &-1 {
              font-size: 18px;
              margin-bottom: 12px;
            }
            &-2 {
              font-size: 34px;
              font-weight: bold;
              margin-bottom: 30px;
            }
            &-3 {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              flex-wrap: nowrap;
              margin: auto;
              margin-bottom: 40px;

              .line {
                &-1 {
                  margin-bottom: 10px;
                  display: flex;
                }
                &-2 {
                  display: flex;
                }
              }

              .row-item {
                display: flex;
                align-items: center;
                .label {
                  // color: rgba(255, 242, 170, 1);
                  color: white;
                  font-size: 15px;
                }

                .bar {
                  width: 1px;
                  height: 15px;
                  background: linear-gradient(
                    180deg,
                    rgba(239, 151, 151, 0) 0%,
                    #ffafaf 50%,
                    rgba(255, 255, 255, 0) 100%
                  );
                  opacity: 0.45;
                  margin: 0 17px;
                }
              }
            }

            &-4 {
              .qrcode-wrap {
                .qrcode-image {
                  display: flex;
                  justify-content: center;
                  img {
                    width: 110px;
                    height: 110px;
                  }
                }
                .label {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 1);
                  text-align: center;
                  line-height: 35px;
                }
              }
            }

            &-5 {
            }
          }
        }
      }

      // 盒子2
      &-2 {
        background-image: url(../../../assets/images/home-top-bg-2-row.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 650px;
        background-color: rgba(247, 250, 253, 1);
        padding: 20px 12px;
        box-sizing: border-box;
        .wrapper-content {
          margin: auto;

          .wrapp-title {
            margin-bottom: 20px;
            .sub-title {
              width: 240px;
              line-height: 20px;
            }
          }

          .wrapp-group-box {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, auto);
            .group-item {
              padding: 8px;

              .item {
                transition: border-color 300ms;
                background: #ffffff;
                box-shadow: 0px 0px 20px 1px rgba(82, 110, 255, 0.1);
                border: 1px solid #ffffff;
                border-radius: 8px;
                text-align: center;
                box-sizing: border-box;
                padding: 18px 14px 0;
                height: 180px;
                &:hover {
                  border-color: #005de8;
                }

                .image {
                  margin: auto;
                  width: 42px;
                  height: 42px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .title {
                  font-size: 13px;
                  margin: 10px auto;
                  font-weight: bold;
                  color: rgba(51, 51, 51, 1);
                }
                .text {
                  font-size: 12px;
                  line-height: 16px;
                  color: rgba(153, 153, 153, 1);
                }
              }
            }
          }
        }
      }

      // 盒子3
      &-3 {
        .wrapper-content {
          padding: 20px;

          .wrapp-title {
            margin-bottom: 20px;
            .sub-title {
            }
          }
          .wrapp-group-box {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            .list-card-container {
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }

              .card-item {
                padding: 0;
                .item {
                  font-size: 14px;
                  .image {
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .title {
                    margin-top: 10px !important;
                    width: 180px;
                    height: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
      }

      // 盒子4
      &-4 {
        background-color: rgba(247, 250, 253, 1);
        .wrapper-content {
          .wrapp-title {
            padding: 20px;
            padding-bottom: 15px;
          }

          .wrapp-group-box {
            display: grid;

            .wrap {
              &-left {
                width: 100%;
                overflow-x: auto;
                white-space: nowrap;
                .list-box {
                  display: flex;
                  position: relative;
                  padding-top: 20px;
                  padding-left: 20px;
                  padding-bottom: 22px;
                  width: fit-content;
                  .active-bar {
                    width: 236px;
                    height: 73px;
                    transition: 300ms;
                    top: 20px;
                    left: 20px;
                    position: absolute;
                    background: rgba(10, 65, 255, 1);
                    border-radius: 6px;
                    box-shadow: 5px 8px 15px 1px rgba(82, 110, 255, 0.5);
                  }

                  .item {
                    display: grid;
                    padding: 15px;
                    grid-template-columns: 1fr auto;
                    width: 236px;
                    box-sizing: border-box;
                    position: relative;
                    z-index: 1;
                    margin-right: 15px;
                    min-width: 236px;

                    &.is-active {
                      .item-left {
                        color: white;
                        transition: color 300ms;

                        .info-label {
                          .title {
                          }
                          .describe {
                            color: #ffffff99;
                            transition: color 300ms;
                          }
                        }
                      }
                      .item-right {
                        .arrow-item {
                          transition: background 300ms, box-shadow 500ms;
                          background-color: white;
                          box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.4);
                        }
                      }
                    }

                    &-left {
                      display: grid;
                      grid-template-columns: auto 1fr;
                      align-items: center;
                      color: rgba(155, 158, 179, 1);
                      transition: color 300ms;
                      .sort-label {
                        font-size: 25px;
                        font-weight: bold;
                        margin-right: 14px;
                      }
                      .info-label {
                        .title {
                          font-size: 12px;
                          font-weight: bold;
                        }
                        .describe {
                          font-size: 12px;
                          line-height: 27px;
                          transition: color 100ms;
                        }
                      }
                    }
                    &-right {
                      display: flex;
                      align-items: flex-end;
                      justify-content: center;
                      margin-right: 10px;
                      width: 30px;

                      .arrow-item {
                        width: 15px;
                        height: 15px;
                        border-radius: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: background 300ms, box-shadow 500ms;
                        margin-bottom: 7px;
                        .row-icon {
                          width: 8px;
                        }
                      }
                    }
                  }
                }
              }
              &-right {
                padding: 20px;
                padding-top: 0;
                .up-title {
                  .title {
                    display: flex;
                    flex-direction: column;
                    .text {
                      font-size: 15px;
                      font-weight: bold;
                      color: rgba(51, 51, 51, 1);
                    }
                    .bar {
                      width: 15px;
                      height: 2px;
                      margin-top: 6px;
                      margin-bottom: 8px;
                    }
                  }
                  .describe {
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    line-height: 25px;
                  }
                }

                .down-content {
                  img {
                    margin-left: -8px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      // 盒子5
      &-5 {
        background-image: url(../../../assets/images/home-top-bg-2-row.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 650px;
        background-color: rgba(247, 250, 253, 1);
        padding: 20px;
        box-sizing: border-box;
        .wrapper-content {
          .wrapp-group-box {
            display: grid;
            grid-template-rows: auto 1fr;
            .wrap {
              &-left {
                position: relative;
                .preview-cover {
                  width: 100%;
                  height: 201px;
                  border-radius: 10px;
                }

                .describe {
                  position: absolute;
                  color: white;
                  bottom: 20px;
                  left: 20px;
                  .title {
                    font-size: 13px;
                    width: calc(100vw - 85px);
                    font-weight: bold;
                  }
                  .label {
                    font-size: 12px;
                    margin-top: 10px;
                  }
                }
              }
              &-right {
                .list {
                  .item {
                    border-bottom: 1px solid rgba(10, 65, 255, 0.1);

                    .time {
                      font-size: 12px;
                      margin-bottom: 15px;
                      margin-top: 20px;
                      color: rgba(153, 153, 153, 1);
                    }
                    .title {
                      color: rgba(44, 44, 44, 1);
                      font-size: 13px;
                      margin-bottom: 20px;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }

          .list-more {
            margin-top: 20px;
          }
        }
      }

      // 盒子6
      &-6 {
        .wrapper-content {
          .wrapp-title {
            margin-top: 30px;
          }
          .wrapp-group-box {
            margin: auto;
            padding: 20px;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            .list {
              position: relative;
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-template-rows: repeat(10, 50px);
              grid-row-gap: 8px;
              justify-items: center;
              align-items: center;
              width: fit-content;
              margin: auto;
              width: 100%;

              .list-item {
                text-align: center;
                ._show {
                  opacity: 1;
                  width: initial;
                  height: initial;
                }
                ._hide {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }

                // &:hover {
                //   .logo-color {
                //     @extend ._show;
                //   }
                //   .logo {
                //     @extend ._hide;
                //   }
                // }
                .logo-color {
                  // @extend ._hide;
                  width: 60%;
                }
              }
            }
          }
        }
      }

      &-7 {
        background-image: url(../../../assets/images/home-top-bg-3.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: auto 450px;
        padding: 1px 0;
        // background-color: white;
        .wrapper-content {
        }
      }
    }

    .describe-list {
      padding: 30px 45px;
      .row-item {
        padding: 23px;
        display: grid;
        grid-template-columns: auto 1fr;
        background-color: white;
        box-shadow: 0px 0px 20px 1px rgba(82, 110, 255, 0.16);
        border-radius: 6px;
        margin-bottom: 25px;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        .image {
          width: 40px;
          height: 40px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .right {
          margin-left: 16px;
          .title {
            color: rgba(51, 51, 51, 1);
            font-weight: bold;
            font-size: 16px;
          }
          .text {
            margin-top: 4px;
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }
  }
}
</style>
